<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div>
           
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                      label="A partir de :"
                      label-for="h-first-name"
                      label-cols-md="4"
                  >

                    <cleave
                        id="date"
                        v-model="date.from"
                        class="form-control"
                        :raw="false"
                        :options="{
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        }"
                        placeholder="JJ/MM/AAAA"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label=" jusqu'au"
                      label-for="h-email"
                      label-cols-md="4"
                  >
                    <cleave
                        id="date"
                        v-model="date.to"
                        class="form-control"
                        :raw="false"
                        :options="{
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        }"
                        placeholder="JJ/MM/AAAA"
                    />
                  </b-form-group>
                </b-col>



                <!-- submit and reset -->
                <b-col offset-md="4">
                  <b-button
                      
                      :disabled="loading"
                      @click="importHandler('show')"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                  >
                    Rechercher
                  </b-button>

                </b-col>
              </b-row>

            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="numberOfLeads != 0">
      <b-col>
        <b-card>
          <div class="mb-2 d-flex justify-content-between">
            <div class="align-self-center">
              {{numberOfLeads}} Leads
            </div>
            <b-button
                variant="primary"
                @click="importHandler('export')"
                :disabled="loading"
            >
          
              Exporter
            </b-button>
          
          </div>
          <b-table
              striped
              hover
              :items="leads"
              :fields="fields"
           
              
          
          >
             
            <template #cell(gitId)="row">
              <b-avatar
                  :src="`https://ui-avatars.com/api/?name=${row.value}&background=0D8ABC&color=fff`"
                  size="1.5rem"
                  variant="light"
              />
              <span class="ml-2">{{ row.value }}</span>
            </template>
            <template #cell(creationDate)="row">
              <span class="ml-2">{{ row.value }}</span>
            </template>
            <template #cell(dispatchDate)="row">
              <span class="ml-2">{{ row.value }}</span>
            </template>
            <template #cell(leadStatus)="row">
              <span class="ml-2">{{ row.value }}</span>
            </template>
            <template #cell(applicationSourceCode)="row">
              <span class="ml-2">{{ row.value }}</span>
            </template>
            <template #cell(applicationSourceName)="row">
              <span class="ml-2">{{ row.value }}</span>
            </template>
            <template #cell(leadData)="row">
              <span class="ml-2">{{ row.value.customer.firstname }} {{ row.value.customer.lastname }} </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

  </div>

</template>

<script>

import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
 

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading : false,
      date: {
        from: '28-11-2023',
        to: '30-11-2023',
      },
      fields : [
        {
          key: 'gitId',
          label: 'GitId',
          sortable: true,
          thClass: 'table-head',
          tdClass: 'table-data',
        },
        {
          key: 'creationDate',
          label: 'Date de création',
          sortable: true,
          thClass: 'table-head',
          tdClass: 'table-data',
        },
        {
          key: 'dispatchDate',
          label: 'Date de dispatch',
          sortable: true,
          thClass: 'table-head',
          tdClass: 'table-data',
        },
        {
          key: 'leadStatus',
          label: 'Statut',
          sortable: true,
          thClass: 'table-head',
          tdClass: 'table-data',
        },
       
        {
          key: 'applicationSourceCode',
          label: 'Code source',
          sortable: true,
          thClass: 'table-head',
          tdClass: 'table-data',
        },
        {
          key: 'applicationSourceName',
          label: 'Nom source',
          sortable: true,
          thClass: 'table-head',
          tdClass: 'table-data',
        },
        {
          key: 'leadData',
          label: 'Données',
          sortable: true,
          thClass: 'table-head',
          tdClass: 'table-data',
        },
      
      ],
      leads: [],
      numberOfLeads: 0,
    

    }
  },
  created() {
  
  },
  methods: {
 
    importHandler(a = 'show') {
   
      let _this = this;
      _this.loading = true;
      console.log(a);
      if(a == 'export'){
        
        window.open(process.env.VUE_APP_API_URL + 'api/stellantis/leads?from=' + _this.date.from + '&to=' + _this.date.to + '&type=' + a);
        _this.loading = false;
        return;
      }
      axios.get(
          process.env.VUE_APP_API_URL + 'api/stellantis/leads?from=' + _this.date.from + '&to=' + _this.date.to + '&type=' + a ,
          //{responseType: 'blob'} // !!!
      ).then((response) => {

        if(response.data.code == 200){
          _this.leads = response.data.message;
          _this.numberOfLeads = response.data.nbResults;
        }else{
          _this.$toast({
            component: ToastificationContent,
            props: {
              title : 'Erreur',
              text: response.data.message,
              variant: 'danger',

            },
          })
          return;
        }
        
    
        
        
        
      }).finally(() => {
        _this.loading = false;
      })
    }
  },
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}

</style>
